import './App.scss';
import Dash from './components/Dash';

function App() {
  return (
    <div className="App">
      <Dash > 
        <h3>loading</h3>
      </Dash>
    </div>
  );
}

export default App;
