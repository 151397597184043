import "distlib";
import { FC, useEffect, useRef, useState } from "react";
interface Props {
  // any props that come into the component
}

const Dash: FC<Props> = ({ children }) => {
  const [loading, setloading] = useState(true);
  const elementRef = useRef<HTMLElement>(null);
  useEffect(() => {
    if (elementRef.current) {
      elementRef.current.addEventListener(
        "dashboard-status",
        ({ detail }: any) => {
          console.log(detail);
          ["DASHBOARD_LOADED", "DASHBOARD_ERROR"].includes(detail) &&
            setloading(false);
        }
      );
    }
    return;
  }, [elementRef]);

  return (
    <>
      {loading && children}
      <div className={loading ? "dash-hidden" : "dash-visible"}>
        <analytics-embedded-url
          ref={elementRef}
          apikey="J#W[.qi@/|2F-3LfXe\P/=w5-Lp6'lT#"
          href="https://krh4txeq4j.execute-api.eu-west-1.amazonaws.com/prod/dashboards/99978d98-5a7c-4d18-845c-32bdc7237898"
        />
      </div>
    </>
  );
};
export default Dash;
